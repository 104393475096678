import "%modules%/header/header";
import "%modules%/header-menu/header-menu";
import "%modules%/intro/intro";
import "%modules%/about/about";
import "%modules%/questions/questions";
import "%modules%/news-search/news-search";
import "%modules%/news-block/news-block";
import "%modules%/select/select";
import "%modules%/photo/photo";
import "%modules%/gallery/gallery";
import "%modules%/stages/stages";
import "%modules%/characteristics/characteristics";
import "%modules%/panel/panel";
