$(() => {
    'use strict';

    var mainScript = (function($){
        var $window = $(window),
            $body = $('body'),
            $document = $(document);

        function init(){
            _bindHandlers();
            initMPSlider();
        }

        function _bindHandlers(){
            console.log('bind handlers')
        }

        function getAll(selector) {
            /** Bulma functions */
            var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;
            return Array.prototype.slice.call(parent.querySelectorAll(selector), 0);
        }

        function getDimesions(count){
            var baseBlock,
                addBlock,
                dimensions,
                blocks = [
                    '[1,1],',
                    '[1,1],[2,1],',
                ]
            if ( count % 3 != 0 ) {
                baseBlock = Math.floor( count / 3 );
                addBlock = count % 3;
            }
            else {
                baseBlock = count / 3;
                addBlock = 0;
            }
            dimensions = blocks[1].repeat(baseBlock) + blocks[0].repeat(addBlock);
            return JSON.parse("[" + dimensions.slice(0,-1) + "]");
        }

        function initMPSlider(){

            var slides = getAll('.js-gallery-slider .splide__slide');
            var dim = getDimesions( $(slides).length );
            console.log(dim)
            new Splide( '.js-gallery-slider',{
                type: 'loop',
                height: '450px',
                fixedWidth: '350px',
                perPage: 2,
                perMove: 1,
                role: 'region',
                grid: {
                    dimensions: dim
                }
            } ).mount(window.splide.Extensions);

        }

        return {
            init: init,
        }

    })(jQuery);

//jQuery(document).ready(mainScript.init);
    jQuery(mainScript.init);
})
