$(() => {
    const $aboutSlider = $(".js-about-slider");
    // const arrows = $awardsSlider.data("arrows").split(",");

    if(!!$aboutSlider.length) {
        $aboutSlider.slick({
            // infinite: true,
            // swipeToSlide: true,
            // arrows: false,
            // centerMode: false,
            // variableWidth: true,
            // adaptiveHeight: true,

            centerMode: false,
            infinite: true,
            variableWidth: true,
            adaptiveHeight: true,

            // prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            // nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,

            responsive: [
                {
                    breakpoint: 100000,
                    settings: "unslick"
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                        dots: true,
                    },
                },
            ]
        })
    }
})
