$(function() {

    const introSlider = $(".js-intro-slider");
    // const arrows = $awardsSlider.data("arrows").split(",");

    if(!!introSlider.length) {
        introSlider.slick({
            infinite: true,
            swipeToSlide: true,
            arrows: false,
            centerMode: false,
            slidesToShow: 1,
            dots: true,

            // prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            // nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,
        })
    }
});
