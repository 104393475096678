$(function() {
    $("[data-bull]").mouseenter((e) => {
        var _bull = $(e.target).attr("data-bull");
        $("[data-point]").removeClass("active");
        $("[data-point='" + _bull + "']").addClass("active");
    });
    $("[data-bull]").mouseleave((e) => {
        $("[data-point]").removeClass("active");
    });
    $("[data-point]").mouseenter((e) => {
        var _point = $(e.target).attr("data-point");
        $("[data-bull]").removeClass("active");
        $("[data-bull='" + _point + "']").addClass("active");
    });
    $("[data-point]").mouseleave((e) => {
        $("[data-bull]").removeClass("active");
    });
});
