$(function () {
    const $questionListHead = $(".js-list-head");

    $questionListHead.on("click", function(){
        $(this).closest(".js-question-group").toggleClass("active");
    });

    // if (!$questionListHead.length) return

    // initFirstActive()

    // $questionHeadArrow.on("click", function(e) {
    //     resetAll();
    //     const that = $(this);
    //     const thatParent = that.parent();
    //     thatParent.addClass("question__head_show");
    //     const nextEl = thatParent.next();
    //     nextEl.addClass("questions__body_show");
    //     nextEl.css("maxHeight", nextEl[0].scrollHeight + "px");
    //     nextEl.parent().addClass("questions__item_show");
    //     if (thatParent.hasClass("question__head_show")) {
    //         e.stopImmediatePropagation()
    //         e.stopPropagation();
    //         that.parent().removeClass("question__head_show");
    //         nextEl.removeClass("questions__body_show");
    //         nextEl.parent().removeClass("questions__item_show")
    //             .removeAttr("style");
    //     }
    // })
    // $questionHeadArrow.on("click", ".question__head-arrow_close", function (e) {
    //     e.stopImmediatePropagation()
    //     e.stopPropagation();
    //
    //     const that = $(this);
    //     const thatParent = that.parent().removeClass("question__head_show");
    //     const nextEl = thatParent.next().removeClass("questions__body_show");
    //
    //     nextEl.parent().removeClass("questions__item_show")
    //         .removeAttr("style");
    //
    //     // thatParent.removeClass("questions__head_show")
    //     //     .next().removeClass("questions__body_show")
    //     //     .removeAttr("style")
    //     // thatParent.parent().removeClass("questions__item_show")
    // })
    //
    // function resetAll() {
    //     $questionHeadArrow.removeClass("questions__head_show")
    //         .next().removeClass("questions__body_show")
    //         .removeAttr("style")
    // }

    //
    // function resetAll() {
    //     $questionListHead.removeClass("questions__head_show")
    //         .next().removeClass("questions__body_show")
    //         .removeAttr("style")
    // }

    // function initFirstActive() {
    //     const firstNextEl =  $questionListHead.eq(0).addClass("questions__head_show").next()[0]
    //
    //     firstNextEl.style.maxHeight = firstNextEl.scrollHeight + "px"
    //     firstNextEl.classList.add("questions__body_show")
    // }
})
