$(function() {
    const $newsSlider = $(".js-news-slider");
    // const arrows = $awardsSlider.data("arrows").split(",");

    if(!!$newsSlider.length) {
        $newsSlider.slick({
            infinite: true,
            swipeToSlide: true,
            arrows: false,
            centerMode: false,
            slidesToShow: 3,
            variableWidth: true,
            adaptiveHeight: true,
            rows: 2,

            // prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            // nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,

            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        rows: 3,
                    },
                },
            ]
        })
    }

    const $newSlider = $(".js-new-slider");
    // const arrows = $awardsSlider.data("arrows").split(",");

    if(!!$newSlider.length) {
        $newSlider.slick({
            infinite: false,
            swipeToSlide: true,
            arrows: false,
            centerMode: false,
            slidesToShow: 3,
            variableWidth: true,
            adaptiveHeight: true,

            // prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            // nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,

            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ]
        })
    }
});
