$(function() {
    const $select2Single = $(".js-single-select")

    $select2Single.select2({
        // dropdownAutoWidth : true,
        width: '100%',
    });
    $select2Single.filter('.is-valid').siblings('.select2').addClass('is-valid')

    onChange($select2Single)
    onClear($select2Single)
    onOpen($select2Single)

    // const $select2Multi = $(".js-multi-select")
    //
    // $select2Multi.select2({
    //     closeOnSelect : false,
    //     // dropdownAutoWidth : true,
    //     placeholder: "Комплектация",
    //     width: '100%',
    //     allowHtml: true,
    //     allowClear: true,
    //     tags: true,
    //     theme: "class-test",
    //
    // });
    //
    //
    // $select2Multi.on("change.select2", function(event) {
    //     const that = $(this)
    //     const dataPostTitle = that.data("post-title")
    //     const selectedOptions = that.val()
    //     const $paramsRender = that.next().find(".select2-selection__rendered")
    //     $paramsRender.children(".select2-selection__choice").remove()
    //     $paramsRender.append(`<span class="select2-selection__place">Выбран ${selectedOptions.length + " " + dataPostTitle} </span>`)
    // })
    //
    //
    // onChange($select2Multi)
    // onClear($select2Multi)
    // onOpen($select2Multi)
})

function onOpen($select2) {
    $select2.on("select2:open", function (e) {

        if($(this).closest(".js-popup-filter")[0]) {
            $(".select2-dropdown").parent().addClass("select2-dropdown__over-first")
        } else {
            $(".select2-dropdown").parent().removeClass("select2-dropdown__over-first")
        }
    })
}

function onChange($select2) {
    $select2.on('change', function (e) {
        const target = $(e.target);
        if (!!target.val().length) {
            target.next().addClass("is-valid")
        } else {
            target.next().removeClass("is-valid")
        }
    });
}

function onClear($select2) {
    $select2.on('select2:clear', function (e) {
        const target = $(e.target);
        target.next().removeClass("is-valid")
    })
}
