$(() => {
    const $stagesSlider = $(".js-stages-slider");
    // const arrows = $awardsSlider.data("arrows").split(",");

    if(!!$stagesSlider.length) {
        $stagesSlider.slick({
            dots: true,
            infinite: true,
            swipeToSlide: true,
            arrows: false,
            centerMode: false,
            // slidesToShow: 3,
            variableWidth: true,
            // variableWidth: true,

            // prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            // nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,

            responsive: [
                {
                    breakpoint: 100000,
                    settings: "unslick"
                },
                {
                    breakpoint: 1025,
                    settings: {
                        adaptiveHeight: true,
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ]
        })
    }
})
