$(function() {
    const { $window } = window;
    const $headerMenuBtn = $(".js-header-menu-btn");

    $headerMenuBtn.on("click", function() {
        const that = $(this);
        that.closest(".js-header").toggleClass("header-menu_open");
    })

    $window.on("click", function(e) {
        if (!e.target.closest(".js-header")) {
            $headerMenuBtn.closest(".js-header").removeClass("header-menu_open");
        }
    })
})
