$(function() {

    // возвращает куки с указанным name,
    // или undefined, если ничего не найдено
    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function setCookie(name, value, options = {}) {
        options = {
          path: '/',
          // при необходимости добавьте другие значения по умолчанию
          ...options
        };
      
        if (options.expires instanceof Date) {
          options.expires = options.expires.toUTCString();
        }
      
        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
      
        for (let optionKey in options) {
          updatedCookie += "; " + optionKey;
          let optionValue = options[optionKey];
          if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
          }
        }
      
        document.cookie = updatedCookie;
    }


    const openPanelbtn = $('.js-open-panel')
    const closePanelbtn = $('.js-close-panel')
    const resetbtn = $('.js-reset')
    const htmlEl = $('.poor-vision')
    let font = 16

    const openPanel = function (evt) {
        htmlEl.addClass('poor-vision_show-panel')
    }

    const closePanel = function (evt) {
        htmlEl.removeClass('poor-vision_show-panel')
    }

    const reset = function (evt) {
        htmlEl.removeClass('poor-vision_grayscale')
        htmlEl.removeClass('poor-vision_white-on-black')
        htmlEl.removeClass('poor-vision_black-on-blue')
        htmlEl.removeClass('poor-vision_green-on-brown')
        htmlEl.removeClass('poor-vision_brown-on-beige')
        htmlEl.removeClass('poor-vision_no-picture')
        htmlEl.css({fontSize: '16px'})
        htmlEl.removeClass('poor-vision_letter-spacing-m')
        htmlEl.removeClass('poor-vision_letter-spacing-l')
        htmlEl.removeClass('poor-vision_line-height-m')
        htmlEl.removeClass('poor-vision_line-height-l')

        setCookie('POOR_VISION_FONT_SIZE', '');
        setCookie('POOR_VISION_PICTURE_CLASS', '');
        setCookie('POOR_VISION_COLORS_CLASS', '');
        setCookie('POOR_VISION_LETTER_SPACING_CLASS', '');
        setCookie('POOR_VISION_LINE_HEIGHT_CLASS', '');
    }

    // --------------------------------------------

    const increaseFontbtn = $('.js-increase-font-btn')
    const decreaseFontbtn = $('.js-decrease-font-btn')

    const decreaseFont = function () {
        if (font > 16) {
            font -= 2;
            htmlEl.css({fontSize: font+'px'})
        }
        setCookie('POOR_VISION_FONT_SIZE', font+'px');
    }

    const increaseFont = function () {
        if (font <= 20) {
            font += 2;
            htmlEl.css({fontSize: font+'px'})
        }
        setCookie('POOR_VISION_FONT_SIZE', font+'px');
    }

    // ---------------------------------------------

    const pictureOnbtn = $('.js-picture-on')
    const pictureOffbtn = $('.js-picture-off')
    const pictureGraybtn = $('.js-picture-gray')

    const pictureOn = function () {
        htmlEl.removeClass('poor-vision_no-picture')
        htmlEl.removeClass('poor-vision_grayscale')
        setCookie('POOR_VISION_PICTURE_CLASS', '');
    }

    const pictureOff = function () {
        htmlEl.addClass('poor-vision_no-picture')
        htmlEl.removeClass('poor-vision_grayscale')
        setCookie('POOR_VISION_PICTURE_CLASS', 'poor-vision_no-picture');
    }

    const pictureGray = function () {
        htmlEl.addClass('poor-vision_grayscale')
        htmlEl.removeClass('poor-vision_no-picture')
        setCookie('POOR_VISION_PICTURE_CLASS', 'poor-vision_grayscale');
    }

    // ----------------------------------------------

    const blackOnWhitebtn = $('.js-black-on-white')
    const whiteOnBlackbtn = $('.js-white-on-black')
    const blackOnBluebtn = $('.js-black-on-blue')
    const greenOnBrownbtn = $('.js-green-on-brown')
    const brownOnBeigebtn = $('.js-brown-on-beige')

    const blackOnWhite = function (evt) {
        htmlEl.removeClass('poor-vision_white-on-black')
        htmlEl.removeClass('poor-vision_black-on-blue')
        htmlEl.removeClass('poor-vision_green-on-brown')
        htmlEl.removeClass('poor-vision_brown-on-beige')
        setCookie('POOR_VISION_COLORS_CLASS', '');
    }

    const whiteOnBlack = function (evt) {
        htmlEl.addClass('poor-vision_white-on-black')
        htmlEl.removeClass('poor-vision_black-on-blue')
        htmlEl.removeClass('poor-vision_green-on-brown')
        htmlEl.removeClass('poor-vision_brown-on-beige')
        setCookie('POOR_VISION_COLORS_CLASS', 'poor-vision_white-on-black');
    }

    const blackOnBlue = function (evt) {
        htmlEl.addClass('poor-vision_black-on-blue')
        htmlEl.removeClass('poor-vision_white-on-black')
        htmlEl.removeClass('poor-vision_green-on-brown')
        htmlEl.removeClass('poor-vision_brown-on-beige')
        setCookie('POOR_VISION_COLORS_CLASS', 'poor-vision_black-on-blue');
    }

    const greenOnBrown = function (evt) {
        htmlEl.addClass('poor-vision_green-on-brown')
        htmlEl.removeClass('poor-vision_white-on-black')
        htmlEl.removeClass('poor-vision_black-on-blue')
        htmlEl.removeClass('poor-vision_brown-on-beige')
        setCookie('POOR_VISION_COLORS_CLASS', 'poor-vision_green-on-brown');
    }

    const brownOnBeige = function (evt) {
        htmlEl.addClass('poor-vision_brown-on-beige')
        htmlEl.removeClass('poor-vision_white-on-black')
        htmlEl.removeClass('poor-vision_green-on-brown')
        htmlEl.removeClass('poor-vision_black-on-blue')
        setCookie('POOR_VISION_COLORS_CLASS', 'poor-vision_brown-on-beige');
    }

    // ----------------------------------------------

    const letterSpacingNbtn = $('.js-letter-spacing-n')
    const letterSpacingMbtn = $('.js-letter-spacing-m')
    const letterSpacingLbtn = $('.js-letter-spacing-l')

    const letterSpacingN = function (evt) {
        htmlEl.removeClass('poor-vision_letter-spacing-m')
        htmlEl.removeClass('poor-vision_letter-spacing-l')
        setCookie('POOR_VISION_LETTER_SPACING_CLASS', '');
    }

    const letterSpacingM = function (evt) {
        htmlEl.addClass('poor-vision_letter-spacing-m')
        htmlEl.removeClass('poor-vision_letter-spacing-l')
        setCookie(
            'POOR_VISION_LETTER_SPACING_CLASS',
            'poor-vision_letter-spacing-m'
        );
    }

    const letterSpacingL = function (evt) {
        htmlEl.removeClass('poor-vision_letter-spacing-m')
        htmlEl.addClass('poor-vision_letter-spacing-l')
        setCookie(
            'POOR_VISION_LETTER_SPACING_CLASS',
            'poor-vision_letter-spacing-l'
        );
    }

    // ----------------------------------------------

    const lineHeightNbtn = $('.js-line-height-n')
    const lineHeightMbtn = $('.js-line-height-m')
    const lineHeightLbtn = $('.js-line-height-l')

    const lineHeightN = function (evt) {
        htmlEl.removeClass('poor-vision_line-height-m')
        htmlEl.removeClass('poor-vision_line-height-l')
        setCookie('POOR_VISION_LINE_HEIGHT_CLASS', '');
    }

    const lineHeightM = function (evt) {
        htmlEl.addClass('poor-vision_line-height-m')
        htmlEl.removeClass('poor-vision_line-height-l')
        setCookie('POOR_VISION_LINE_HEIGHT_CLASS', 'poor-vision_line-height-m');
    }

    const lineHeightL = function (evt) {
        htmlEl.removeClass('poor-vision_line-height-m')
        htmlEl.addClass('poor-vision_line-height-l')
        setCookie('POOR_VISION_LINE_HEIGHT_CLASS', 'poor-vision_line-height-l');
    }

    const checkPoorVisionLocalSettings = function (event) {
        const poorVisionFontSize = getCookie('POOR_VISION_FONT_SIZE');
        if (poorVisionFontSize) {
            document.documentElement.style.fontSize = poorVisionFontSize;
            font = Number(poorVisionFontSize.replace('px', ''));
        }
        [
            'POOR_VISION_PICTURE_CLASS',
            'POOR_VISION_COLORS_CLASS',
            'POOR_VISION_LETTER_SPACING_CLASS',
            'POOR_VISION_LINE_HEIGHT_CLASS',
        ].forEach(settingName => {
            const settingValue = getCookie(settingName);
            if (settingValue) {
                document.documentElement.classList.add(settingValue);
            }
        });
    }

    const $document = $(document);

    // ----------------------------------------------
   
    openPanelbtn.on('click', openPanel)
    closePanelbtn.on('click', closePanel)
    resetbtn.on('click', reset)

    increaseFontbtn.on('click', increaseFont)
    decreaseFontbtn.on('click', decreaseFont)

    pictureOnbtn.on('click', pictureOn)
    pictureOffbtn.on('click', pictureOff)
    pictureGraybtn.on('click', pictureGray)

    blackOnWhitebtn.on('click', blackOnWhite)
    whiteOnBlackbtn.on('click', whiteOnBlack)
    blackOnBluebtn.on('click', blackOnBlue)
    greenOnBrownbtn.on('click', greenOnBrown)
    brownOnBeigebtn.on('click', brownOnBeige)

    letterSpacingNbtn.on('click', letterSpacingN)
    letterSpacingMbtn.on('click', letterSpacingM)
    letterSpacingLbtn.on('click', letterSpacingL)

    lineHeightNbtn.on('click', lineHeightN)
    lineHeightMbtn.on('click', lineHeightM)
    lineHeightLbtn.on('click', lineHeightL)

    checkPoorVisionLocalSettings();
        
});
